.App {
  height: 100vh;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.flex-center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  background-color: #282c34;
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-main {
  background-color: #282c34;
  min-height: 60vh;  
  font-size: calc(10px + 1.5vmin);
  color: white;
}

.App-footer {
  height: 20vh;
  background-color: #282c34;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.preview-img-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.6em;
  display: none;
}

.image-transform-area {
  position: relative;
  min-height: 100px;
  min-width: 100px;
  width: 100%;
}

.image-fade {
  max-height: 300px;
  height: 35vh; 
  width: 100%;
  overflow: hidden;
  position: relative;
}

.image-transform {
  position: absolute;  
  max-height: 300px;
  height: 35vh;
  z-index: 20;
}

.button {
  min-width: 12em;
  min-height: 3em;
  margin-top: 2em;
  z-index: 20;
}

.star-area {
  position: absolute;
  width: 100vw;
  height: 50vh;
  background: #000;
  z-index: 0;
  top: 0;
  left: 0;  
}

@keyframes twinkling {
  0% {
    opacity: 1;
    background-color: lightgray;
    width: 100%;
    height: 100%;
  }
  40% {
    opacity: 0.7;
    background-color: rgb(238, 238, 147);
    width: 80%;
    height: 80%;
  }
  50% {
    opacity: 0.3;
    background-color: red;
    width: 55%;
    height: 55%;
  }
  60% {
    opacity: 0.7;
    background-color: rgb(238, 238, 147);
    width: 80%;
    height: 80%;
  }
  100% {
    opacity: 1;
    background-color: lightgray;
    width: 100%;
    height: 100%;
  }
}

@keyframes moveRobot {
  0% {
    transform: perspective(100px) rotateY(0deg);
    transform-style: preserve-3d;
  }
  20%{
    transform: perspective(200px) rotateY(8deg) scale(1.02);
    transform-style: preserve-3d;
  }
  40%{
    transform: perspective(150px) rotateY(14deg) scale(1.05);
    transform-style: preserve-3d;
  }
  60%{
    transform: perspective(150px) rotateY(5deg) scale(1.03);
    transform-style: preserve-3d;
  }
  80%{
    transform: perspective(150px) rotateY(-4deg) scale(1.02);
    transform-style: preserve-3d;
  }
  100%{
    transform: perspective(100px) rotateY(0deg) scale(1.0);
    transform-style: preserve-3d;
  }
}

.star-animation {
  animation: twinkling 10s linear 2s infinite;
};

